import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./Components/NavBar";
import Bets from "./Pages/Bets";
import Home from "./Pages/Home";
import Matches from "./Pages/Matches";
import Matches_Details from "./Pages/Matches_Details";
import Odds from "./Pages/Odds";
import Odds_Details from "./Pages/Odds_Details";
import "./App.css";
import Leagues from "./Pages/Leagues";
import BetsCalculation from "./Pages/BetsCalculation";
import Details from "./Pages/Details";
import BetLog from "./Pages/BetLog";
import DrawerMenu from "./Components/DrawerMenu";
import Login from "./Pages/Login";
import RequireAuth from "./Components/Auth/RequireAuth";

const App = () => {
  return (
    <div>
      <DrawerMenu />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Bets />} />
        <Route path="/matches/" element={<Home />} />
        <Route path="/details/:id" element={<Details />} />
        <Route path="/matches" element={<Matches />} />
        <Route path="/matches/:id" element={<Matches_Details />} />
        <Route path="/odds" element={<Odds />} />
        <Route path="/odds/:id" element={<Odds_Details />} />
        <Route path="/betlog" element={<BetLog />} />
        <Route path="/leagues" element={<Leagues />} />
        <Route path="/betscalculation" element={<BetsCalculation />} />
      </Routes>
    </div>
  );
};

export default App;
