import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTable, usePagination } from "react-table";
import { Link } from "react-router-dom";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const MatchesTable = () => {
  const [matchData, setMatchData] = useState([false]);
  const [tableData, setTableData] = useState([]);
  const [filterStatusParam, setFilterStatusParam] = useState("");
  const [startParam, setStartParam] = useState("");
  const [countParam, setCountParam] = useState("");
  const [filterStartFromParam, setFilterStartFromParam] =
    useState("2022-04-12");
  const [filterStartToParam, setFilterStartToParam] = useState("");

  useEffect(() => {
    const parseDate = (inputDate) => {
      if (inputDate === "") return false;
      const splitDate = inputDate.split("-");
      const parsedDate = `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
      console.log(parsedDate);
      return parsedDate;
    };
    const config = {
      method: "get",
      url: `${BACKEND_URL}/api/v1/matches?${
        filterStatusParam ? "filter[status]=done&" : ""
      }${startParam ? "start=" + startParam + "&" : ""}${
        countParam ? "count=" + countParam + "&" : ""
      }${
        filterStartFromParam
          ? "filter[startFrom]=" + parseDate(filterStartFromParam) + "&"
          : ""
      }${
        filterStartToParam
          ? "filter[startTo]=" + parseDate(filterStartToParam) + "&"
          : ""
      }
      `,
      headers: {},
    };
    console.log(config.url);
    const getData = async () => {
      try {
        const response = await axios(config);
        console.log(response);
        setMatchData(response.data.data);
      } catch {
        alert("Error");
      }
    };
    getData();
    return () => {};
  }, [
    filterStatusParam,
    startParam,
    countParam,
    filterStartFromParam,
    filterStartToParam,
  ]);

  useEffect(() => {
    setTableData(
      matchData.map((item) => {
        return {
          col1: item.host,
          col2: item.guest,
          col3: new Date(item.startTime * 1000).toString(),
          col4: item.league,
          col5: <Link to={`/matches/${item.id}`}>Details</Link>,
        };
      })
    );
    return () => {};
  }, [matchData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Home",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "Away",
        accessor: "col2",
      },
      {
        Header: "Start Time",
        accessor: "col3",
      },
      {
        Header: "League",
        accessor: "col4",
      },
      {
        Header: "Details",
        accessor: "col5",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  return (
    <div className="container">
      <label htmlFor="filterStatus">
        Status: Done?
        <input
          type="checkbox"
          id="filterStatus"
          name="filterStatus"
          value={filterStatusParam}
          onChange={() => {
            setFilterStatusParam((prevState) => !prevState);
          }}
        />
      </label>
      <label htmlFor="start">
        Start Index:
        <input
          type="number"
          id="start"
          name="start"
          value={startParam}
          onChange={(e) => {
            setStartParam(e.target.value);
          }}
        />
      </label>
      <label htmlFor="count">
        Page Count:
        <input
          type="number"
          id="count"
          name="count"
          value={countParam}
          onChange={(e) => {
            setCountParam(e.target.value);
          }}
        />{" "}
      </label>{" "}
      <label htmlFor="startFrom">
        From:
        <input
          type="date"
          id="startFrom"
          name="startFrom"
          value={filterStartFromParam}
          min="2018-01-01"
          max="2024-12-31"
          onChange={(e) => {
            setFilterStartFromParam(e.target.value);
          }}
        />
      </label>
      <label htmlFor="startTo">
        To:
        <input
          type="date"
          id="startTo"
          name="startTo"
          value={filterStartToParam}
          min="2018-01-01"
          max="2024-12-31"
          onChange={(e) => {
            setFilterStartToParam(e.target.value);
          }}
        />
      </label>
      <table {...getTableProps()} style={{ border: "solid 1px blue" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    background: "aliceblue",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions?.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default MatchesTable;
