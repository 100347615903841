import React from "react";

const MatchFinalScore = ({ host, guest, segment }) => {

  return (
    <table className="finalScore">
      <thead>
        <tr>
          <th></th>
          <th>{`${segment[0]?.hostScore} - ${segment[0]?.guestScore}`}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{host}</td>
          <td>Full Time</td>
          <td>{guest}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default MatchFinalScore;
