import React from "react";
import "antd/dist/antd.css";
import "./index.scss";
import { Table, Tooltip } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

const MatchesTable = ({ data }) => {
  const currentLeagueList = data.map((item) => {
    return item.league;
  });
  const currentLeagueListUnique = currentLeagueList.filter(
    (v, i, a) => a.indexOf(v) === i
  );
  const leaguesFilter = currentLeagueListUnique.map((item) => {
    return { text: item, value: item };
  });

  const toolTipGenerator = (value) => {
    return (
      <Tooltip placement="bottom" title={value}>
        {value}
      </Tooltip>
    );
  };


  const columns = [
    {
      title: "League",
      dataIndex: "league",
      key: "league",
      width: 100,
      fixed: "left",
      filters: leaguesFilter,
      sorter: (a, b) => a.league - b.league,
      onFilter: (value, record) => record.league.indexOf(value) === 0,
    },
    {
      title: "Host",
      dataIndex: "host",
      key: "host",
      width: 100,
      fixed: "left",
    },
    {
      title: "Guest",
      dataIndex: "guest",
      key: "guest",
      width: 100,
      fixed: "left",
    },

    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      width: 100,
      fixed: "left",
      render: (number) => moment.unix(number).format("ddd D MMM YY HH:mm:ss"),
      sorter: (a, b) => a.startTime - b.startTime,
    },
    {
      title: "Match Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      fixed: "left",
    },
    {
      title: "Details",
      dataIndex: "id",
      key: "details",
      width: 100,
      fixed: "left",
      render: (id) => {
        return <Link to={`/details/${id}`}>Details</Link>;
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 100,
      fixed: "left",
      render: (number) => moment(number).format("ddd D MMM YY HH:mm:ss"),
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 100,
      fixed: "left",
      render: (number) => moment(number).format("ddd D MMM YY HH:mm:ss"),
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="id"
      bordered
      size="middle"
      scroll={{
        y: 600,
      }}
    />
  );
};

export default MatchesTable;
