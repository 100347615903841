import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { MenuItem, Tooltip } from "@mui/material";
import TableNoEntries from "../Components/GeneralComponents/TableNoEntries";
import CalculationTable from "../Components/Bets/CalculationTable";
import BetsParameterInputs from "../Components/Bets/BetsParameterInputs";
import BetsTable from "../Components/Bets/BetsTable";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Bets = () => {
  const [betsData, setBetsData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams({});
  const currentParams = Object.fromEntries([...searchParams]);
  const [bookieList, setBookieList] = useState(
    currentParams["filter[source]"] ? [currentParams["filter[source]"]] : []
  );
  const [leagueList, setLeagueList] = useState(
    currentParams["filter[league]"] ? [currentParams["filter[league]"]] : []
  );

  useEffect(() => {
    const config = {
      method: "get",
      url: `${BACKEND_URL}/api/v1/bets?${searchParams ? searchParams : ""}`,
      headers: {},
    };
    console.log(config.url);
    setBetsData([]);
    const getData = async (config, currentStart) => {
      try {
        const response = await axios(config);
        console.log(response);
        setBetsData((prevState) => prevState.concat(response.data.data));
        setBookieList((prevState) =>
          [
            ...prevState,
            ...response.data.data.map((item) => item.source),
          ].filter((v, i, a) => a.indexOf(v) === i)
        );
        setLeagueList((prevState) =>
          [
            ...prevState,
            ...response.data.data.map((item) => item.match.league),
          ].filter((v, i, a) => a.indexOf(v) === i)
        );
        if (
          response.data.total > 1000 &&
          response.data.data.length === 1000 &&
          (+currentParams.count > 1000 || !currentParams.count)
        ) {
          const newConfig = {
            method: "get",
            url: `${BACKEND_URL}/api/v1/bets?start=${
              +currentStart + 1000
            }&count=1000${
              currentParams["filter[source]"]
                ? `&filter[source]=` + currentParams["filter[source]"]
                : ""
            }${
              currentParams["filter[league]"]
                ? `&filter[league]=` + currentParams["filter[league]"]
                : ""
            }${
              currentParams["filter[startFrom]"]
                ? `&filter[startFrom]=` + currentParams["filter[startFrom]"]
                : ""
            }${
              currentParams["filter[startTo]"]
                ? `&filter[startTo]=` + currentParams["filter[startTo]"]
                : ""
            }`,
          };
          console.log(newConfig.url);
          getData(newConfig, `${+currentStart + 1000}`);
        }
      } catch {
        alert("Error");
      }
    };
    getData(config, currentParams.start ? currentParams.start : "0");
    return () => {};
  }, [searchParams]);

  const removeDuplicateEntries = (array) => {
    return [...new Map(array.map((item) => [item["id"], item])).values()];
  };

  const validBets = removeDuplicateEntries(betsData).filter(
    (item) => item.unityId && item.unityId != "not_found"
  );

  const earliestMatchDate = moment
    .unix(betsData[betsData.length - 1]?.match.startTime)
    .format("ddd D MMM YY");
  const latestMatchDate = moment
    .unix(betsData[0]?.match.startTime)
    .format("ddd D MMM YY");

  const calculateEdge = (bet) => {
    switch (bet.selected) {
      case "HOME":
        return (bet.odd.rateOver - bet.homeTrueOdd) / bet.homeTrueOdd;
        break;
      case "AWAY":
        return (bet.odd.rateUnder - bet.awayTrueOdd) / bet.awayTrueOdd;
        break;
      case "DRAW":
        return (bet.odd.rateEqual - bet.drawTrueOdd) / bet.drawTrueOdd;
        break;
    }
  };

  const givenOdds = (bet) => {
    switch (bet.selected) {
      case "HOME":
        return bet.odd.rateOver;
        break;
      case "AWAY":
        return bet.odd.rateUnder;
        break;
      case "DRAW":
        return bet.odd.rateEqual;
        break;
    }
  };

  const calculateWinnings = (bet) => {
    switch (bet.selected) {
      case "HOME":
        return bet.odd.rateOver * bet.amount - bet.amount;
        break;
      case "AWAY":
        return bet.odd.rateUnder * bet.amount - bet.amount;
        break;
      case "DRAW":
        return bet.odd.rateEqual * bet.amount - bet.amount;
        break;
    }
  };

  const tooltipGenerator = (params) => {
    return (
      <Tooltip title={params.value ? params.value : "Loading"} arrow>
        <div>{params?.value}</div>
      </Tooltip>
    );
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70, hide: true },
    { field: "status", headerName: "Status", flex: 1, hide: true },
    {
      field: "startTime",
      headerName: "Start Time",
      flex: 2,
      renderCell: (params) =>
        moment.unix(params.row.startTime).format("ddd D MMM YY HH:mm:ss"),
    },
    {
      field: "league",
      headerName: "League",
      flex: 2,
      renderCell: (params) => tooltipGenerator(params),
    },

    {
      field: "host",
      headerName: "Home",
      flex: 2,
      renderCell: (params) => tooltipGenerator(params),
    },
    {
      field: "guest",
      headerName: "Away",
      flex: 2,
      renderCell: (params) => tooltipGenerator(params),
    },
    {
      field: "homeTrueOdd",
      headerName: "Home",
      flex: 0.8,
    },
    {
      field: "drawTrueOdd",
      headerName: "Draw",
      flex: 0.8,
    },
    {
      field: "awayTrueOdd",
      headerName: "Away",
      flex: 0.8,
    },
    {
      field: "selected",
      headerName: "Selection",
      flex: 1,
    },
    { field: "winner", headerName: "Winner", flex: 1 },
    {
      field: "source",
      headerName: "Bookie",
      flex: 1,
    },
    { field: "givenOdds", headerName: "Given (O)", flex: 1 },
    {
      field: "edge",
      headerName: "Edge",
      type: "number",
      flex: 0.8,
      hide: true,
      renderCell: (params) => {
        return `${params.value}%`;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      renderCell: (params) => {
        return `$${params.value}`;
      },
    },
    {
      field: "expectedWinnings",
      headerName: "Expected Winnings",
      flex: 1,
      renderCell: (params) => {
        if (params.value != undefined) {
          return params.value >= 0
            ? `$${params?.value?.toFixed(2)}`
            : `-$${Math.abs(params.value.toFixed(2))}`;
        }
      },
    },
    { field: "betResult", headerName: "Bet Result", flex: 1 },
    {
      field: "net",
      headerName: "Net",
      flex: 1,
      renderCell: (params) => {
        if (params.value != undefined) {
          return params.value >= 0
            ? `$${params?.value?.toFixed(2)}`
            : `-$${Math.abs(params.value.toFixed(2))}`;
        }
      },
    },
    // {
    //   field: "details",
    //   headerName: "Details",
    //   width: 100,
    //   renderCell: (params) => {
    //     return <Link to={`/${params.value}`}>Details</Link>;
    //   },
    //   hide: true,
    // },git
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) =>
        moment(params.row.createdAt).format("ddd D MMM YY HH:mm:ss"),
      hide: true,
    },
    {
      field: "valid",
      headerName: "Valid?",
      flex: 1,
    },
  ];

  const tableData = removeDuplicateEntries(betsData)
    .filter((item) => item?.unityId && item?.unityId != "not_found")
    .map((item, index) => {
      return {
        key: index,
        id: item?.id,
        startTime: item?.match?.startTime,
        source: item?.source,
        league: item?.match?.league,
        host: item?.match?.host,
        guest: item?.match?.guest,
        homeTrueOdd: item?.homeTrueOdd,
        drawTrueOdd: item?.drawTrueOdd,
        awayTrueOdd: item?.awayTrueOdd,
        homeGivenOdds: item?.odd.rateOver,
        drawGivenOdds: item?.odd.rateEqual,
        awayGivenOdds: item?.odd.rateUnder,
        selected: item?.selected,
        winner: item?.winners ? item?.winners : "Pending",
        givenOdds: givenOdds(item),
        edge: +(calculateEdge(item) * 100).toFixed(2),
        amount: item?.amount,
        expectedWinnings: +(calculateEdge(item) * item.amount).toFixed(2),
        status: item?.match?.status,
        details: item?.matchId,
        betResult:
          item?.winners != null
            ? item?.winners === item?.selected
              ? "Win"
              : "Loss"
            : "Pending",
        net:
          item?.selected === item?.winners
            ? +calculateWinnings(item).toFixed(2)
            : -item?.amount,
        createdAt: item?.createdAt,
        updatedAt: item?.updatedAt,
        unityId: item?.unityId,
        valid:
          item?.unityId && item?.unityId != "not_found" ? "Yes ✅" : "No ❌",
      };
    });

  //CSV file generation
  const csvData = tableData.map((item) => {
    return {
      ...item,
      winner: item.winner,
      startTime: moment.unix(item.startTime).format("DD-MM-YY HH:mm:ss"),
      createdAt: moment(item.createdAt).format("DD-MM-YY HH:mm:ss"),
      updatedAt: moment(item.updatedAt).format("DD-MM-YY HH:mm:ss"),
      unityId: item.unityId,
    };
  });
  console.log(earliestMatchDate);
  return (
    <section id="bets">
      <BetsParameterInputs
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        currentParams={currentParams}
        bookieList={bookieList}
        leagueList={leagueList}
        csvData={csvData}
      />
      <CalculationTable validBets={validBets} />
      <br /> 
      {/* {earliestMatchDate} - {latestMatchDate} */}
      {earliestMatchDate != "Invalid date"
        ? `${earliestMatchDate} - ${latestMatchDate}`
        : "No entries found"}
      <BetsTable data={tableData} leagueList={leagueList} />
    </section>
  );
};

export default Bets;
