import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from "./App";
import DrawerMenu from "./Components/DrawerMenu";
import { AuthProvider } from "./Context/AuthProvider";

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <DrawerMenu />
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
