import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTable, usePagination } from "react-table";
import { Link } from "react-router-dom";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const LeaguesTable = () => {
  const [leaguesData, setLeaguesData] = useState([false]);
  const [tableData, setTableData] = useState([]);
  const [filterNameParam, setFilterNameParam] = useState("");
  const [filterAcceptBetParam, setFilterAcceptBetParam] = useState("");
  const [countParam, setCountParam] = useState(50);
  const [startParam, setStartParam] = useState(0);
  const [dummyState, setDummyState] = useState(true);

  const changeAcceptBet = (id, value) => {
    console.log(value);
    const data = JSON.stringify({
      acceptBet: value,
    });

    const config = {
      method: "patch",
      url: `${BACKEND_URL}/api/v1/leagues/${id.toString()}?=`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    console.log(config);
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
    setDummyState((prevState) => !prevState);
  };

  useEffect(() => {
    const config = {
      method: "get",
      url: `${BACKEND_URL}/api/v1/leagues?${
        filterNameParam ? "filter[name]=" + filterNameParam + "&" : ""
      }${
        filterAcceptBetParam
          ? "filter[acceptBet]=" + filterAcceptBetParam + "&"
          : ""
      }${countParam ? "count=" + countParam + "&" : ""}start=${startParam}
      `,
      headers: {},
    };
    console.log(config.url);
    const getData = async () => {
      try {
        const response = await axios(config);
        console.log(response);
        setLeaguesData(response.data.data);
      } catch {
        alert("Error");
      }
    };
    getData();
    return () => {};
  }, [filterNameParam, filterAcceptBetParam, startParam, countParam, dummyState]);

  useEffect(() => {
    setTableData(
      leaguesData.map((item) => {
        return {
          col1: item.name,
          col2: "Details",
          col3: item.acceptBet ? "Yes" : "No",
          col4: (
            <label htmlFor="filterStatus">
              <input
                type="checkbox"
                id="acceptBetTick"
                name="acceptBetTick"
                checked={item.acceptBet}
                onChange={() => changeAcceptBet(item.id, !item.acceptBet)}
              />
            </label>
          ),
        };
      })
    );
    setPageSize(50);
    return () => {};
  }, [leaguesData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "League",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "Details",
        accessor: "col2",
      },
      {
        Header: "Accept Bets?",
        accessor: "col3",
      },
      {
        Header: "Change Accept Bet",
        accessor: "col4",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  return (
    <div className="container">
      <label htmlFor="name">
        Name:
        <input
          type="text"
          id="name"
          name="name"
          value={filterNameParam}
          onChange={(e) => {
            setFilterNameParam(e.target.value);
          }}
        />{" "}
      </label>{" "}
      <label htmlFor="filterAcceptBetParam">
        Accept Bets?
        <select
          name="filterAcceptBetParam"
          id="filterAcceptBetParam"
          onChange={(e) => {
            setFilterAcceptBetParam(e.target.value);
          }}
        >
          <option value="">Any</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
        {/* <input
          type="checkbox"
          id="filterAcceptBetParam"
          name="filterAcceptBetParam"
          value={filterAcceptBetParam}
          onChange={() => {
            filterAcceptBetParam === ""
              ? setFilterAcceptBetParam(true)
              : setFilterAcceptBetParam((prevState) => !prevState);
          }}
        /> */}
      </label>
      <table {...getTableProps()} style={{ border: "solid 1px blue" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    background: "aliceblue",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => setStartParam(0)} disabled={startParam === 0}>
          {"<<"}
        </button>{" "}
        <button
          onClick={() => setStartParam((prevState) => prevState - countParam)}
          disabled={startParam === 0}
        >
          {"<"}
        </button>{" "}
        <button
          onClick={() => setStartParam((prevState) => prevState + countParam)}
        >
          {">"}
        </button>{" "}
        {/* <button
          onClick={() => setStartParam((prevState) => prevState + 1)}
          disabled={leaguesData.length === 0}
        >
          {">>"}
        </button>{" "} */}
        {/* <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions?.length}
          </strong>{" "}
        </span> */}
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={startParam + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              setStartParam(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={countParam}
          onChange={(e) => {
            setCountParam(Number(e.target.value));
            setPageSize(Number(e.target.value));
          }}
        >
          {[50, 100, 150].map((countParam) => (
            <option key={countParam} value={countParam}>
              Show {countParam}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default LeaguesTable;
