import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MatchDetailsTable from "../Components/MatchDetailsTable";
import axios from "axios";
import MatchFinalScore from "../Components/MatchFinalScore";
const BACKEND_URL=process.env.REACT_APP_BACKEND_URL

const Matches_Details = () => {
  let { id } = useParams();
  const [matchDetailsData, setMatchDetailsData] = useState({});
  useEffect(() => {
    const config = {
      method: "get",
      url: `${BACKEND_URL}/api/v1/matches/${id}`,
      headers: {},
    };

    const getData = async () => {
      try {
        const response = await axios(config);
        console.log(response);
        setMatchDetailsData(response.data);
      } catch {
        alert("Error");
      }
    };
    getData();
    return () => {};
  }, []);

  const segment = (string) => {
    return matchDetailsData?.events?.filter(
      (entry) => entry.segment === string
    );
  };
  console.log(segment("SECOND_HALF"));

  return (
    <div>
      <MatchFinalScore
        host={matchDetailsData.host}
        guest={matchDetailsData.guest}
        segment={segment("SECOND_HALF") || []}
      />
      <MatchDetailsTable
        host={matchDetailsData.host}
        guest={matchDetailsData.guest}
        segment={segment("FIRST_HALF") || []}
      />
      <MatchDetailsTable
        host={matchDetailsData.host}
        guest={matchDetailsData.guest}
        segment={segment("SECOND_HALF") || []}
      />
    </div>
  );
};

export default Matches_Details;
