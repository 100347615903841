import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MatchDetailsTable from "../Components/MatchDetailsTable";
import axios from "axios";
import MatchFinalScore from "../Components/MatchFinalScore";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Odds_Details = () => {
  let { id } = useParams();
  const [oddsDetails, setOddsDetailsData] = useState({});
  useEffect(() => {
    const config = {
      method: "get",
      url: `${BACKEND_URL}/api/v1/odds/${id}`,
      headers: {},
    };

    const getData = async () => {
      try {
        const response = await axios(config);
        console.log(response);
        setOddsDetailsData(response.data);
      } catch {
        alert("Error");
      }
    };
    getData();
    return () => {};
  }, []);

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Source</th>
            <th>Type</th>
            <th>Format</th>
            <th>Lb Type</th>
            <th>Time Type</th>
            <th>Pivot Value</th>
            <th>Pivot Bias</th>
            <th>Pivot Type</th>
            <th>Is Swapped</th>
            <th>Rate Over</th>
            <th>Rate Under</th>
            <th>Rate Equal</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{oddsDetails.source}</td>
            <td>{oddsDetails.type}</td>
            <td>{oddsDetails.format}</td>
            <td>{oddsDetails.lbType}</td>
            <td>{oddsDetails.timeType}</td>
            <td>{oddsDetails.pivotValue}</td>
            <td>{oddsDetails.pivotBias}</td>
            <td>{oddsDetails.pivotType}</td>
            <td>{oddsDetails.isSwapped ? "Yes" : "No"}</td>
            <td>{oddsDetails.rateOver}</td>
            <td>{oddsDetails.rateUnder}</td>
            <td>{oddsDetails.rateEqual}</td>
          </tr>
        </tbody>
      </table>
      <h1>Match</h1>
      <table>
<thead>
  <tr>
    <th>League</th>
    <th>Host</th>
    <th>Guest</th>
    <th>Start Time</th>
    <th>Status</th>
  </tr>
</thead>
<tbody>
  <tr>
    <td>{oddsDetails.match?.league}</td>
    <td>{oddsDetails.match?.host}</td>
    <td>{oddsDetails.match?.guest}</td>
    <td>{new Date(oddsDetails.match?.startTime * 1000).toString()}</td>
    <td>{oddsDetails.match?.status}</td>
  </tr>
</tbody>
</table>
    </div>
  );
};

export default Odds_Details;
