import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MatchDetailsTable from "../Components/MatchDetailsTable";
import axios from "axios";
import MatchFinalScore from "../Components/MatchFinalScore";
import moment from "moment";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Details = () => {
  let { id } = useParams();
  const [betDetailsData, setBetDetailsData] = useState([{ selected: "Test" }]);
  const [matchDetailsData, setMatchDetailsData] = useState([
    { selected: "Test" },
  ]);
  useEffect(() => {
    const configBets = {
      method: "get",
      url: `${BACKEND_URL}/api/v1/bets/?filter[matchId]=${id}`,
      headers: {},
    };
    const configMatch = {
      method: "get",
      url: `${BACKEND_URL}/api/v1/matches/${id}`,
      headers: {},
    };

    const getDataBets = async () => {
      try {
        const response = await axios(configBets);
        console.log(response);
        setBetDetailsData(response.data.data);
      } catch {
        alert("Error");
      }
    };
    const getDataMatch = async () => {
      try {
        const response = await axios(configMatch);
        console.log(response);
        setMatchDetailsData(response.data);
      } catch {
        alert("Error");
      }
    };
    getDataBets();
    getDataMatch();
  }, []);

  const matchData = (match) => {
    return (
      <table>
        <thead>
          <tr>
            <th>League</th>
            <th>Home</th>
            <th>Away</th>
            <th>Start Time</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{match?.league}</td>
            <td>{match?.host}</td>
            <td>{match?.guest}</td>
            <td>
              {moment.unix(match?.startTime).format("ddd D MMM YY HH:mm")}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const sortedByBookie = betDetailsData.sort(function (a, b) {
    const textA = a.source.toUpperCase();
    const textB = b.source.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  const uniqueBookies = sortedByBookie.filter((value, index, self) => {
    return self.findIndex((v) => v.source === value.source) === index;
  });

  const individualBookieBets = (source) => {
    return betDetailsData?.filter((item) => item.source === source);
  };
  console.log(individualBookieBets("BETFAIR"));

  const mappedIndividualBetsByBookie = (source) => individualBookieBets(source);
  console.log(mappedIndividualBetsByBookie("BETFAIR"));
  const calculateWinnings = (bet) => {
    switch (bet.selected) {
      case "HOME":
        return (bet.odd.rateOver - 1) * bet.amount;
        break;
      case "AWAY":
        return (bet.odd.rateUnder - 1) * bet.amount;
        break;
      case "DRAW":
        return (bet.odd.rateEqual - 1) * bet.amount;
        break;
    }
  };

  const totalWinningsArray = betDetailsData.map((item) => {
    if (item.selected != item.winners) {
      return -item.amount;
    }
    return calculateWinnings(item);
  });
  const sumOfWinnings = totalWinningsArray.reduce(
    (accumulated, currentValue) => accumulated + currentValue,
    0
  );

  console.log(sumOfWinnings);

  const mappedBets = (source) =>
    mappedIndividualBetsByBookie(source).map((item, index) => {
      return (
        <tr key={index}>
          <td>
            Bet #{index + 1} on {item.selected}
          </td>
          <td>{item.odd?.rateOver}</td>
          <td>{item.odd?.rateUnder}</td>
          <td>{item.odd?.rateEqual}</td>
          <td>${item.amount}</td>
          <td>{item.selected === item.winners ? "Yes" : "No"}</td>
          <td>
            {item.selected === item.winners
              ? `$${calculateWinnings(item)}`
              : `-$${item.amount}`}
          </td>
        </tr>
      );
    });

  //   const displayIndividualBookieBets = (source) => {
  //     individualBookieBets(source).map((item, index) => {
  //       return (
  //         <tr key={index}>
  //           <td>HIIII</td>
  //           <td>{item.odd?.rateOver}</td>
  //           <td></td>
  //           <td></td>
  //           <td></td>
  //         </tr>
  //       );
  //     });
  //   };
  //   console.log(displayIndividualBookieBets("GA288"));

  const bettingData = uniqueBookies.map((item, index) => {
    return (
      <Fragment key={index}>
        <thead>
          <tr>
            <th rowSpan="2">Bookie</th>
            <th colSpan="3">True Odds</th>
            <th rowSpan="2">Winner</th>
          </tr>
          <tr>
            {/* <th>Bet amount</th> */}
            <th>Home</th>
            <th>Away</th>
            <th>Draw</th>
            {/* <th>Selection</th> */}

            {/* <th>Winning Bet?</th> */}
          </tr>
        </thead>
        <tbody>
          <tr key={index}>
            <th>{item.source}</th>
            {/* <th>{item.amount}</th> */}
            <th>{item.homeTrueOdd}</th>
            <th>{item.awayTrueOdd}</th>
            <th>{item.drawTrueOdd}</th>
            {/* <th>{item.selected}</th> */}
            <th>{item.winners}</th>
            {/* <th>{item.selected === item.winners ? "Yes" : "No"}</th> */}
          </tr>
          {/* {displayIndividualBookieBets("BETFAIR")} */}
          <tr>
            <th rowSpan="2">Bets</th>
            <th colSpan="3">Given Odds</th>
            <th rowSpan="2">Bet Size</th>
            <th rowSpan="2">Bet Win?</th>
            <th rowSpan="2">Net Profit</th>
          </tr>
          <tr>
            <th>Home</th>
            <th>Away</th>
            <th>Draw</th>
          </tr>
          {mappedBets(item.source)}
        </tbody>
      </Fragment>
    );
  });

  return (
    <div>
      {matchData(matchDetailsData)}
      <table>
        {betDetailsData.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan="100%">No bets recorded</td>
            </tr>
          </tbody>
        ) : (
          bettingData
        )}
        <tbody>
          <tr>
            <td colSpan={6}>Total Winnings</td>
            <td>
              {sumOfWinnings >= 0
                ? `$${sumOfWinnings}`
                : `-$${Math.abs(sumOfWinnings)}`}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Details;
