import {
  Select,
  Form,
  Radio,
  DatePicker,
  Button,
  Tooltip,
  Row,
  Col,
  Input,
} from "antd";
import { data } from "autoprefixer";
import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import useAuth from "../CustomHooks/useAuth";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Login = () => {
  const { setAuth } = useAuth();
  const [email, setEmail] = useState("");
  const [requireOTP, setRequireOTP] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const handleSubmitFailed = async (e) => {
    console.log("Failed!");
  };

  const handleSubmit = async (e) => {
    let data = qs.stringify(e);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/api/v1/login`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };
    try {
      const response = await axios(config);
      console.log(response);
      setRequireOTP(response.data.data.requireOTP);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitOTP = async (e) => {
    let data = qs.stringify({ ...{ email: email }, ...e });
    console.log(data);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/api/v1/login/verify`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };
    try {
      const response = await axios(config);
      console.log(response);
      setAuth(response?.data?.data);
      navigate(from, { replace: true });
    } catch (err) {
      console.log(err);
    }
  };
  if (!requireOTP)
    return (
      <section>
        <h1>Sign In</h1>
        <Form onFinish={handleSubmit} onFinishFailed={handleSubmitFailed}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              autoComplete="false"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input type="password" autoComplete="false" width="300vw" />
          </Form.Item>
          <Button type="primary" htmlType="Submit">
            Submit
          </Button>
        </Form>
      </section>
    );
  if (requireOTP)
    return (
      <section>
        <h1>Enter OTP</h1>
        <Form onFinish={handleSubmitOTP} onFinishFailed={handleSubmitFailed}>
          <Form.Item
            label="OTP"
            name="otpCode"
            rules={[
              {
                required: true,
                message: "Please input OTP!",
              },
            ]}
          >
            <Input autoComplete="false" width="300vw" />
          </Form.Item>
          <Button type="primary" htmlType="Submit">
            Submit
          </Button>
        </Form>
      </section>
    );
};

export default Login;
