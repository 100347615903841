import React from "react";

const MatchDetailsTable = ({ host, guest, segment }) => {
  return (
    <table className="details">
      <thead>
        <tr>
          <th>{host}</th>
          <th></th>
          <th>{guest}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{segment[0]?.hostScore}</td>
          <td>
            {segment[0]?.segment === "FIRST_HALF"
              ? "First Half"
              : "Second Half"}
          </td>
          <td>{segment[0]?.guestScore}</td>
        </tr>
        <tr>
          <td>{segment[0]?.hostCard}</td>
          <td>Cards</td>
          <td>{segment[0]?.guestCard}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default MatchDetailsTable;
