import React from "react";
import "antd/dist/antd.css";
import { Table, Tooltip } from "antd";
import moment from "moment";
import "./Bets.scss";
import { textAlign } from "@mui/system";

const BetsTable = ({ data }) => {
  const currentLeagueList = data.map((item) => {
    return item.league;
  });
  const currentLeagueListUnique = currentLeagueList.filter(
    (v, i, a) => a.indexOf(v) === i
  );
  const leaguesFilter = currentLeagueListUnique.map((item) => {
    return { text: item, value: item };
  });

  const columns = [
    {
      title: <div style={{ textAlign: "center" }}>Start Time</div>,
      dataIndex: "startTime",
      key: "startTime",
      width: 130,
      fixed: "left",
      render: (number) => moment.unix(number).format("ddd D MMM YY HH:mm:ss"),
      sorter: (a, b) => a.startTime - b.startTime,
    },
    {
      title: <div style={{ textAlign: "center" }}>League</div>,
      dataIndex: "league",
      key: "league",
      width: 200,
      fixed: "left",
      onFilter: (value, record) => record.league.indexOf(value) === 0,
    },
    {
      title: <div style={{ textAlign: "center" }}>Home</div>,
      dataIndex: "host",
      key: "host",
      width: 200,
      fixed: "left",
    },
    {
      title: <div style={{ textAlign: "center" }}>Away</div>,
      dataIndex: "guest",
      key: "guest",
      width: 200,
      fixed: "left",
    },
    {
      title: <div style={{ textAlign: "center" }}>True Odds</div>,

      width: 150,
      fixed: "left",
      children: [
        {
          title: "Home",
          dataIndex: "homeTrueOdd",
          key: "homeTrueOdd",
          width: 70,
          fixed: "left",
          align: "center",
        },
        {
          title: "Draw",
          dataIndex: "drawTrueOdd",
          key: "drawTrueOdd",
          width: 70,
          fixed: "left",
          align: "center",
        },
        {
          title: "Away",
          dataIndex: "awayTrueOdd",
          key: "awayTrueOdd",
          width: 70,
          fixed: "left",
          align: "center",
        },
      ],
    },
    {
      title: "Selection",
      dataIndex: "selected",
      key: "selection",
      width: 100,
      fixed: "left",
      align: "center",
    },
    {
      title: "Edge",
      dataIndex: "edge",
      key: "edge",
      width: 100,
      fixed: "left",
      align: "center",
      render: (number) => number + '%',
      sorter: (a, b) => a.edge - b.edge,
    },
    {
      title: "Winner",
      dataIndex: "winner",
      key: "winner",
      width: 100,
      fixed: "left",
      align: "center",
    },
    {
      title: "Bookie",
      dataIndex: "source",
      key: "source",
      width: 100,
      fixed: "left",
      align: "center",
    },
    {
      title: "Given (O)",
      dataIndex: "givenOdds",
      key: "givenOdds",
      width: 100,
      fixed: "left",
      align: "center",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 80,
      fixed: "left",
      align: "center",
      render: (amount) => `$${amount.toFixed(2)}`
    },
    {
      title: "Expected Winning",
      dataIndex: "expectedWinnings",
      key: "expectedWinnings",
      width: 100,
      fixed: "left",
      align: "center",
      render: (amount) => {
        if (amount != undefined) {
          return amount >= 0
            ? `$${amount.toFixed(2)}`
            : `-$${Math.abs(amount.toFixed(2))}`;
        }
      },
      sorter: (a, b) => a.expectedWinnings - b.expectedWinnings,
    },
    {
      title: "Bet Result",
      dataIndex: "betResult",
      key: "betResult",
      width: 100,
      fixed: "left",
      align: "center",
    },
    {
      title: "Net",
      dataIndex: "net",
      key: "net",
      width: 80,
      fixed: "left",
      align: "center",
      render: (amount) => {
        if (amount != undefined) {
          return amount >= 0
            ? `$${amount.toFixed(2)}`
            : `-$${Math.abs(amount.toFixed(2))}`;
        }
      },
      sorter: (a, b) => a.net - b.net,
    },
    // {
    //   title: "Valid",
    //   dataIndex: "valid",
    //   key: "valid",
    //   width: 80,
    //   fixed: "left",
    //   align: "center",
    // },
  ];
  return (
    <Table
      pagination={{ defaultPageSize: 100, pageSizeOptions: [50, 100, 200], showQuickJumper: true }}
      columns={columns}
      dataSource={data}
      rowKey="key"
      bordered
      size="middle"
      scroll={{
        y: "75vh",
      }}
    />
  );
};

export default BetsTable;
