import React, { useEffect, useState } from "react";
import axios from "axios";
import HomeDateSelector from "../Components/HomeDateSelector";
import moment from "moment";
import { Link, useSearchParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
// import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Box from "@mui/material/Box";
import HomeTable from "../Components/Matches/HomeTable";
import useLocalStorage from "../CustomHooks/useLocalStorage";
import MatchesTable from "../Components/Matches/MatchesTable";
import {
  Select,
  Form,
  Radio,
  DatePicker,
  Button,
  Tooltip,
  Row,
  Col,
} from "antd";
const { Option } = Select;
const { RangePicker } = DatePicker;

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Home = () => {
  const [matchesData, setMatchesData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams({});
  const currentParams = Object.fromEntries([...searchParams]);
  const [leagueList, setLeagueList] = useState([
    currentParams["filter[league]"],
  ]);
  const [form] = Form.useForm();
  console.log(currentParams);
  useEffect(() => {
    const config = {
      method: "get",
      url: `${BACKEND_URL}/api/v1/matches?${searchParams ? searchParams : ""}`,
      headers: {},
    };
    console.log(config.url);
    setMatchesData([]);
    const getData = async (config, currentStart) => {
      try {
        const response = await axios(config);
        console.log(response);
        setLeagueList((prevState) =>
          [
            ...prevState,
            ...response.data.data.map((item) => item.league),
          ].filter((v, i, a) => a.indexOf(v) === i)
        );
        setMatchesData((prevState) => prevState.concat(response.data.data));
        if (
          response.data.total > 1000 &&
          response.data.data.length === 1000 &&
          (+currentParams.count > 1000 || !currentParams.count)
        ) {
          const newConfig = {
            method: "get",
            url: `${BACKEND_URL}/api/v1/matches?start=${
              +currentStart + 1000
            }&count=1000${
              currentParams["filter[league]"]
                ? `&filter[league]=` + currentParams["filter[league]"]
                : ""
            }${
              currentParams["filter[startFrom]"]
                ? `&filter[startFrom]=` + currentParams["filter[startFrom]"]
                : ""
            }${
              currentParams["filter[startTo]"]
                ? `&filter[startTo]=` + currentParams["filter[startTo]"]
                : ""
            }`,
          };
          console.log(newConfig.url);
          getData(newConfig, `${+currentStart + 1000}`);
        }
      } catch {
        alert("Error");
      }
    };
    getData(config, currentParams.start ? currentParams.start : "0");
    return () => {};
  }, [searchParams]);

  // useEffect(() => {
  //   const parseDate = (inputDate) => {
  //     if (inputDate === "") return false;
  //     const parsedDate = moment(inputDate).format("DD/MM/YYYY");
  //     return parsedDate;
  //   };
  //   const config = {
  //     method: "get",
  //     url: `${BACKEND_URL}/api/v1/matches?${
  //       filterStatusParam ? `filter[status]=` + filterStatusParam + "&" : ""
  //     }${startParam ? "start=" + startParam + "&" : ""}${
  //       countParam ? "count=" + countParam + "&" : ""
  //     }${
  //       filterStartFromParam
  //         ? "filter[startFrom]=" + parseDate(filterStartFromParam) + "&"
  //         : ""
  //     }${
  //       filterStartToParam
  //         ? "filter[startTo]=" + parseDate(filterStartToParam) + "&"
  //         : ""
  //     }
  //     `,
  //     headers: {},
  //   };
  //   console.log(config.url);
  //   const getData = async () => {
  //     try {
  //       const response = await axios(config);
  //       console.log(response);
  //       setMatchesData(response.data.data);
  //     } catch {
  //       alert("Error");
  //     }
  //   };
  //   getData();
  //   return () => {};
  // }, [
  //   filterStatusParam,
  //   startParam,
  //   countParam,
  //   filterStartFromParam,
  //   filterStartToParam,
  // ]);

  const removeDuplicateEntries = (array) => {
    return [...new Map(array.map((item) => [item["id"], item])).values()];
  };

  const resetParams = () => {
    setSearchParams({});
    form.resetFields();
    form.setFieldValue("league", "")
  };

  const leagueMenuItems = leagueList.sort().map((item, index) => {
    return (
      <Option key={index} value={item}>
        {item}
      </Option>
    );
  });

  return (
    <section>
      <Form form={form}>
        <Row>
          <Col>
            <Form.Item
              label="League"
              name="league"
              labelCol={{ span: 24 }}
            >
              <Select
                size="medium"
                style={{ width: "20vw" }}
                value={
                  currentParams["filter[league]"]
                    ? currentParams["filter[league]"]
                    : ""
                }
                onChange={(e) => {
                  console.log(e);
                  e
                    ? searchParams.set("filter[league]", e)
                    : searchParams.delete("filter[league]");
                  setSearchParams(searchParams);
                }}
              >
                <Option value={""}>Any</Option>
                {leagueMenuItems}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Match Status"
              name="matchStatus"
              labelCol={{ span: 24 }}
            >
              <Radio.Group
                value={currentParams["filter[status]"]}
                onChange={(e) => {
                  console.log(e.target.value);
                  e.target.value
                    ? searchParams.set("filter[status]", e.target.value)
                    : searchParams.delete("filter[status]");
                  setSearchParams(searchParams);
                }}
              >
                <Radio.Button value={undefined}>Any</Radio.Button>
                <Radio.Button value="pending">Pending</Radio.Button>
                <Radio.Button value="done">Done</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Date Range"
              name="dateRange"
              labelCol={{ span: 24 }}
            >
              <RangePicker
                format={"ddd DD/MM/YYYY"}
                style={{ width: "15vw" }}
                onChange={(newValue) => {
                  console.log(newValue);
                  searchParams.set(
                    "filter[startFrom]",
                    newValue[0].format("DD/MM/YYYY")
                  );
                  searchParams.set(
                    "filter[startTo]",
                    newValue[1].format("DD/MM/YYYY")
                  );
                  setSearchParams(searchParams);
                  console.log(currentParams["filter[startFrom]"]);
                }}
              />
            </Form.Item>
          </Col>
          <Button
            style={{ marginTop: "40px" }}
            variant="contained"
            onClick={() => resetParams()}
          >
            Reset
          </Button>
        </Row>
      </Form>
      <MatchesTable
        data={removeDuplicateEntries(matchesData)}
        leagueList={leagueList}
      />
    </section>
  );
};

export default Home;
