// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from "react";
import { Table, Tooltip } from "antd";

const CalculationTable = ({ validBets }) => {
  const calculateEdge = (bet) => {
    switch (bet.selected) {
      case "HOME":
        return (bet.odd.rateOver - bet.homeTrueOdd) / bet.homeTrueOdd;
        break;
      case "AWAY":
        return (bet.odd.rateUnder - bet.awayTrueOdd) / bet.awayTrueOdd;
        break;
      case "DRAW":
        return (bet.odd.rateEqual - bet.drawTrueOdd) / bet.drawTrueOdd;
        break;
    }
  };

  const givenOdds = (bet) => {
    switch (bet.selected) {
      case "HOME":
        return bet.odd.rateOver;
        break;
      case "AWAY":
        return bet.odd.rateUnder;
        break;
      case "DRAW":
        return bet.odd.rateEqual;
        break;
    }
  };
  const numberOfValidBets = validBets.length;

  const totalAmountRisk = validBets.reduce((total, item) => {
    return total + item.amount;
  }, 0);

  const expectedWinnings = (bet) => {
    return parseInt((bet.amount * calculateEdge(bet)).toFixed(2));
  };

  const sumOfExpectedWinnings = validBets.reduce((total, item) => {
    return total + expectedWinnings(item);
  }, 0);

  const expectedROI = sumOfExpectedWinnings / totalAmountRisk;

  const actualWinnings = (bet) => {
    if (bet.selected === bet.winners) {
      return bet.amount * givenOdds(bet) - bet.amount;
    }
    if (bet.selected != bet.winners) {
      return 0 - bet.amount;
    }
  };

  const sumOfActualWinnings = validBets.reduce((total, item) => {
    return total + actualWinnings(item);
  }, 0);

  const actualROI = sumOfActualWinnings / totalAmountRisk;

  const columns = [
    {
      title: "Number of Valid Bets",
      dataIndex: "numberOfValidBets",
      key: "numberOfValidBets",
      width: 100,
      fixed: "left",
      align: "center",
    },
    {
      title: "Total Amount Risk",
      dataIndex: "totalAmountRisk",
      key: "totalAmountRisk",
      width: 100,
      fixed: "left",
      align: "center",
      render: (amount) => (amount > 0 ? `$${amount.toFixed(2)}` : `-$${amount.toFixed(2)}`),
    },
    {
      title: "Sum of Expected Winnings",
      dataIndex: "sumOfExpectedWinnings",
      key: "sumOfExpectedWinnings",
      width: 100,
      fixed: "left",
      align: "center",
      render: (amount) => (amount > 0 ? `$${amount.toFixed(2)}` : `-$${Math.abs(amount).toFixed(2)}`),
    },
    {
      title: "Expected ROI",
      dataIndex: "expectedROI",
      key: "expectedROI",
      width: 100,
      fixed: "left",
      align: "center",
      render: (decimal) => {
        return ((decimal * 100).toFixed(2) + "%");
      },
    },
    {
      title: "Sum of Actual Winnings",
      dataIndex: "sumOfActualWinnings",
      key: "sumOfActualWinnings",
      width: 100,
      fixed: "left",
      align: "center",
      render: (amount) => (amount > 0 ? `$${amount.toFixed(2)}` : `-$${Math.abs(amount).toFixed(2)}`),
    },
    {
      title: "Actual ROI",
      dataIndex: "actualROI",
      key: "actualROI",
      width: 100,
      fixed: "left",
      align: "center",
      render: (decimal) => {
        return ((decimal * 100).toFixed(2) + "%");
      },
    },
  ];

  const calculationsData = [
    {
      id: "0",
      numberOfValidBets,
      totalAmountRisk,
      sumOfExpectedWinnings,
      expectedROI,
      sumOfActualWinnings,
      actualROI,
    },
  ];

  return (
    <Table columns={columns} dataSource={calculationsData} pagination={false} rowKey="id"/>
    //   <TableContainer>
    //   <Table sx={{ width: "50vw", mb: 5 }} aria-label="simple table">
    //     <TableHead>
    //       <TableRow>
    //         <TableCell align="right">Total Amount Risk</TableCell>
    //         <TableCell align="right">Sum of Expected Winnings</TableCell>
    //         <TableCell align="right">Expected ROI</TableCell>
    //         <TableCell align="right">Sum of Actual Winnings</TableCell>
    //         <TableCell align="right">Actual ROI</TableCell>
    //       </TableRow>
    //     </TableHead>
    //     <TableBody>
    //       {calculationsData.map((row, index) => (
    //         <TableRow
    //           key={index}
    //           sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    //         >
    //           <TableCell align="right">${row.totalAmountRisk}</TableCell>
    //           <TableCell align="right">
    //             ${row.sumOfExpectedWinnings}
    //           </TableCell>
    //           <TableCell align="right">
    //             {(row.expectedROI * 100).toFixed(2) === "NaN"
    //               ? "N/A"
    //               : `${(row.expectedROI * 100).toFixed(2)}%`}
    //           </TableCell>
    //           <TableCell align="right">
    //             {row.sumOfActualWinnings >= 0
    //               ? `$${row.sumOfActualWinnings.toFixed(2)}`
    //               : `-$${Math.abs(row.sumOfActualWinnings.toFixed(2))}`}
    //           </TableCell>
    //           <TableCell align="right">
    //             {(row.actualROI * 100).toFixed(2) === "NaN"
    //               ? "N/A"
    //               : `${(row.actualROI * 100).toFixed(2)}%`}
    //           </TableCell>
    //         </TableRow>
    //       ))}
    //     </TableBody>
    //   </Table>
    // </TableContainer>
  );
};

export default CalculationTable;
