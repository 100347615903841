import React, { useEffect, useState } from "react";
import axios from "axios";
import BetsTable from "../Components/BetsTable";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const BetsCalculation = () => {
  const [betsData, setBetsData] = useState([]);
  const [matchesData, setMatchesData] = useState([]);
  const [matchIdsArray, setMatchIdsArray] = useState([]);
  useEffect(() => {
    const config = {
      method: "get",
      url: `${BACKEND_URL}/api/v1/bets/?count=100&start=1`,
      headers: {},
    };
    const getBetsData = async () => {
      try {
        const response = await axios(config);
        console.log(response);
        setBetsData(response.data.data);
      } catch {
        alert("Error");
      }
    };
    getBetsData();
  }, []);
  useEffect(() => {
    const allBetIds = betsData.map((item) => item.matchId);
    const sortedBetIds = allBetIds.sort();
    const uniqueBetIds = sortedBetIds.filter((v, i, a) => a.indexOf(v) === i);
    setMatchIdsArray(uniqueBetIds);
  }, [betsData]);
  useEffect(() => {
    matchIdsArray.forEach(element => {
      const config = {
        method: "get",
        url: `${BACKEND_URL}/api/v1/matches/${element.toString()}`,
        headers: {},
      };
      const getIndividualMatchData = async () => {
        try {
          const response = await axios(config);
          console.log(response);
          setMatchesData(prevState => [...prevState, response.data.events[1]])
        } catch {
          alert("Error");
        }
      };
      getIndividualMatchData();
    });
  }, [matchIdsArray]);
 const winBet = (hostScore, awayScore, selection) => {
   
 }
  return <div>Hi</div>;
};

export default BetsCalculation;
