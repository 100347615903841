import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { GiHamburgerMenu, GiPayMoney } from "react-icons/gi";
import { NavLink } from "react-router-dom";
import { TiHome } from "react-icons/ti";
import { BsJournalBookmark } from "react-icons/bs";
import { makeStyles } from "@mui/styles";

const DrawerMenu = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const links = [
    { name: "bets", icon: <GiPayMoney /> },
    { name: "matches", icon: <TiHome /> },
    { name: "betlog", icon: <BsJournalBookmark /> },
  ];

  const list = () => (
    <Box
      sx={{
        width: `${(271 / 1447) * 100}vw`,
        marginTop: "66px",
        backgroundColor: "#2B80FF",
        height: "100%",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {/* <div>Username</div>
      <div>Name Premium User</div>
      <h3>Main Menu</h3> */}
      <List>
        {links.map((item) => (
          <ListItem key={item.name} disablePadding>
            <NavLink
              to={`/${item.name === "bets" ? "" : item.name}`}
              key={item.name}
              style={({ isActive }) => ({
                backgroundColor: isActive ? "blue" : "",
                color: isActive ? "white" : "blue",
                width: "100%",
                height: "50px",
              })}
            >
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <span className="capitalize">{item.name}</span>
              </ListItemButton>{" "}
            </NavLink>
          </ListItem>
        ))}
      </List>
      {/* <Divider />
      <List>
        {links.map((item) => (
          <ListItem key={item.name} disablePadding>
            <NavLink
              to={`/${item.name === "home" ? "" : item.name}`}
              key={item.name}
              style={({ isActive }) => ({
                backgroundColor: isActive ? "blue" : "",
              })}
            >
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <span className="capitalize">{item.name}</span>
              </ListItemButton>{" "}
            </NavLink>
          </ListItem>
        ))}
      </List> */}
    </Box>
  );

  const useStyles = makeStyles({
    drawerPaper: {
      marginTop: "66px",
    },
  });

  return (
    <div>
      <div
        style={{
          zIndex: 3000,
          height: "66px",
          width: "100%",
          position: "fixed",
          backgroundColor: "white",
          display: "flex",
        }}
      >
        <Button onClick={toggleDrawer(!drawerOpen)}>
          <GiHamburgerMenu />
        </Button>
      </div>
      <div>
        <Drawer
          anchor={"left"}
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          //   classes={{
          //     paper: useStyles().drawerPaper,
          //   }}
        >
          {list("left")}
        </Drawer>
      </div>
    </div>
  );
};

export default DrawerMenu;
