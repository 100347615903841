// import {
//   Button,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
//   TextField,
//   Tooltip,
// } from "@mui/material";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import React from "react";
import { CSVLink } from "react-csv";
import {
  Select,
  Form,
  Radio,
  DatePicker,
  Button,
  Tooltip,
  Row,
  Col,
} from "antd";
const { Option } = Select;
const { RangePicker } = DatePicker;

const BetsParameterInputs = ({
  searchParams,
  setSearchParams,
  currentParams,
  bookieList,
  leagueList,
  csvData,
}) => {
  const [form] = Form.useForm();
  const bookieMenuItems = bookieList.map((item, index) => {
    return (
      <Option key={index} value={item}>
        {item}
      </Option>
    );
  });

  const leagueMenuItems = leagueList.sort().map((item, index) => {
    return (
      <Option key={index} value={item}>
        {item}
      </Option>
    );
  });

  const resetParams = () => {
    setSearchParams({});
    form.resetFields();
    form.setFieldValue("league", "");
    form.setFieldValue("bookie", "");
  };
  const quickSelectionDate = (selection) => {
    searchParams.set("filter[startFrom]", selection);
    searchParams.set("filter[startTo]", moment().format("DD/MM/YYYY"));
    searchParams.delete("count");
    setSearchParams(searchParams);
  };

  const quickSelectionDateValues = [
    {
      displayedValue: "1D",
      number: 0,
      period: "days",
      description: "Show 1 day data",
    },
    {
      displayedValue: "5D",
      number: 4,
      period: "days",
      description: "Show 5 day data",
    },
    {
      displayedValue: "1M",
      number: 1,
      period: "months",
      description: "Show 1 month data",
    },
    {
      displayedValue: "1Y",
      number: 1,
      period: "years",
      description: "Show 1 year data",
    },
  ];

  const quickSelectionDateTableCells = quickSelectionDateValues.map((item) => {
    return (
      <Tooltip
        placement="bottom"
        title={item.description}
        key={item.displayedValue}
      >
        <td
          style={{
            fontWeight:
              currentParams["filter[startFrom]"] ===
                moment()
                  .subtract(item.number, item.period)
                  .format("DD/MM/YYYY") &&
              currentParams["filter[startTo]"] === moment().format("DD/MM/YYYY")
                ? "600"
                : null,
          }}
          onClick={() =>
            quickSelectionDate(
              moment().subtract(item.number, item.period).format("DD/MM/YYYY")
            )
          }
        >
          {item.displayedValue}
        </td>
      </Tooltip>
    );
  });

  const quickSelectionCount = (selection) => {
    selection
      ? searchParams.set("count", selection)
      : searchParams.delete("count");
    searchParams.set("start", "0");
    searchParams.delete("filter[startFrom]");
    searchParams.delete("filter[startTo]");
    setSearchParams(searchParams);
  };
  const quickSelectionCountValues = [
    { displayedValue: "100", number: "100" },
    { displayedValue: "200", number: "200" },
    { displayedValue: "1k", number: "1000" },
    { displayedValue: "2k", number: "2000" },
    { displayedValue: "Max", number: "" },
  ];

  const quickSelectionCountTableCells = quickSelectionCountValues.map(
    (item) => {
      return (
        <Tooltip
          placement="bottom"
          title={
            item.number ? `Show ${item.number} entries` : "Show all entries"
          }
          key={item.displayedValue}
        >
          <td
            style={{
              fontWeight:
                item.number === currentParams.count ||
                (currentParams.count === undefined &&
                  item.number === "" &&
                  !currentParams["filter[startFrom]"])
                  ? "600"
                  : null,
            }}
            onClick={() => quickSelectionCount(item.number)}
          >
            {item.displayedValue}
          </td>
        </Tooltip>
      );
    }
  );
  const quickSelectionTable = (
    <table style={{ cursor: "pointer", width: "10vw", height: "1vw", marginTop: "30px" }}>
      <tbody>
        <tr>
          {quickSelectionDateTableCells}
          {quickSelectionCountTableCells}
        </tr>
      </tbody>
    </table>
  );

  return (
    <Form form={form} style={{display: "flex", marginLeft: "2vw"}}>
      <Row>
        <Col>
          <Form.Item
            label="League"
            name="league"
            labelCol={{ span: 24 }}
          >
            <Select
              size="medium"
              style={{ width: "20vw" }}
              value={
                currentParams["filter[league]"]
                  ? currentParams["filter[league]"]
                  : ""
              }
              onChange={(e) => {
                console.log(e);
                e
                  ? searchParams.set("filter[league]", e)
                  : searchParams.delete("filter[league]");
                setSearchParams(searchParams);
              }}
            >
              <Option value={""}>Any</Option>
              {leagueMenuItems}
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            label="Bookie"
            name="bookie"
            labelCol={{ span: 24 }}
          >
            <Select
              size="medium"
              style={{ width: "10vw" }}
              value={
                currentParams["filter[source]"]
                  ? currentParams["filter[source]"]
                  : ""
              }
              onChange={(e) => {
                console.log(e);
                e
                  ? searchParams.set("filter[source]", e)
                  : searchParams.delete("filter[source]");
                setSearchParams(searchParams);
              }}
            >
              <Option value={""}>Any</Option>
              {bookieMenuItems}
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            label="Date Range"
            name="dateRange"
            labelCol={{ span: 24 }}
          >
            <RangePicker
              format={"ddd DD/MM/YYYY"}
              style={{ width: "15vw" }}
              onChange={(newValue) => {
                console.log(newValue);
                searchParams.set(
                  "filter[startFrom]",
                  newValue[0].format("DD/MM/YYYY")
                );
                searchParams.set(
                  "filter[startTo]",
                  newValue[1].format("DD/MM/YYYY")
                );
                setSearchParams(searchParams);
                console.log(currentParams["filter[startFrom]"]);
              }}
            />
          </Form.Item>
        </Col>
        {quickSelectionTable}
        <Button
          style={{ marginTop: "40px" }}
          variant="contained"
          onClick={() => resetParams()}
        >
          Reset
        </Button>
        <CSVLink data={csvData}>
          <Button variant="contained" style={{ marginTop: "40px" }}>
            CSV
          </Button>
        </CSVLink>
      </Row>
    </Form>
  );
};

export default BetsParameterInputs;
